exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-adu-jsx": () => import("./../../../src/pages/adu.jsx" /* webpackChunkName: "component---src-pages-adu-jsx" */),
  "component---src-pages-cohousing-jsx": () => import("./../../../src/pages/cohousing.jsx" /* webpackChunkName: "component---src-pages-cohousing-jsx" */),
  "component---src-pages-cottage-jsx": () => import("./../../../src/pages/cottage.jsx" /* webpackChunkName: "component---src-pages-cottage-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mixeduse-jsx": () => import("./../../../src/pages/mixeduse.jsx" /* webpackChunkName: "component---src-pages-mixeduse-jsx" */),
  "component---src-pages-sfc-jsx": () => import("./../../../src/pages/sfc.jsx" /* webpackChunkName: "component---src-pages-sfc-jsx" */),
  "component---src-pages-tinyhouse-jsx": () => import("./../../../src/pages/tinyhouse.jsx" /* webpackChunkName: "component---src-pages-tinyhouse-jsx" */),
  "component---src-pages-townhouse-jsx": () => import("./../../../src/pages/townhouse.jsx" /* webpackChunkName: "component---src-pages-townhouse-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

